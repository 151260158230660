<script setup lang="ts">
  import PaginationLabel from '~/components/Custom/PaginationLabel.vue';
  import type { IPagination } from '~/types';

  const props = defineProps<{
    modelValue: number;
    pagination: IPagination;
    size?: 'xs' | 'md' | 'lg' | 'xl';
  }>();

  const emit = defineEmits(['update:modelValue']);

  const page = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
  });
</script>

<template>
  <div class="flex w-full flex-row items-center justify-between text-xs text-muted-foreground">
    <PaginationLabel :pagination="pagination" />

    <UPagination
      v-model="page"
      :active-button="{ variant: 'solid' }"
      size="md"
      :page-count="pagination.perPage"
      :total="pagination.total"
      :ui="{ rounded: 'first-of-type:rounded-s-md last-of-type:rounded-e-md' }"
    >
      <template #first="{ onClick }">
        <UTooltip text="First page">
          <UButton
            icon="i-heroicons-arrow-uturn-left"
            color="primary"
            :ui="{ rounded: 'rounded-full' }"
            class="me-2 rtl:[&_span:first-child]:rotate-180"
            @click="onClick"
          />
        </UTooltip>
      </template>

      <template #last="{ onClick }">
        <UTooltip text="Last page">
          <UButton
            icon="i-heroicons-arrow-uturn-right-20-solid"
            color="primary"
            :ui="{ rounded: 'rounded-full' }"
            class="ms-2 rtl:[&_span:last-child]:rotate-180"
            @click="onClick"
          />
        </UTooltip>
      </template>
    </UPagination>
  </div>
</template>

<style scoped></style>
